<template>
  <div class="user">
    <FormView :formData="fromData" :formLabels="formLabels" :column="formColumn" :labelWidth="labelWidth">
      <el-button type="success" @click="addActivity">创建活动</el-button>
      <el-button type="warning" @click="reset">重置</el-button>
      <el-button type="primary" @click="search">查询</el-button>
    </FormView>
    <TableView :loading="loading" :columns="columns" :data="rTableData" :pagination="pagination"
      :is-pagination-show="isPaginationShow" @getData="getDataList">
      <template #invitationType>
        <el-table-column label="邀约类型" align="center">
          <template #default="scoped">
            {{ scoped.row.invitationType === 0 ? '公开' : '定向邀约' }}
          </template>
        </el-table-column>
      </template>
      <template #action>
        <el-table-column label="操作" width="230" align="center">
          <template #default="scoped">
            <el-button type="primary" size="small" @click="handleClickLook(scoped.row)">
              详情
            </el-button>
            <el-button type="primary" size="small" @click="handleClickEdit(scoped.row)">
              修改
            </el-button>
            <el-button type="primary" size="small" @click="handleClickDelete(scoped.row)">
              删除
            </el-button>
          </template>
        </el-table-column>
      </template>
    </TableView>
  </div>
</template>

<script setup>
import { reactive, onMounted, toRefs } from 'vue'
import TableView from '@/components/TableView.vue'
import FormView from '@/components/FormView.vue'
import { ElMessageBox, ElMessage } from 'element-plus'
import {
  activityDelete,
  activityList,
  constantAuery
} from '@/api/themeConfiguration'
import { useRouter } from 'vue-router'
const router = useRouter()
const initData = reactive({
  formLabels: [
    {
      label: 'keywords',
      title: '关键字',
      placeholder: '请输入标题或者副标题'
    },
    {
      title: '活动状态',
      label: 'status',
      type: 'options',
      options: [],
      placeholder: '请选择'
    },
    // {
    //   title: '配置类型',
    //   label: 'type',
    //   type: 'options',
    //   options: [],
    //   placeholder: '请选择'
    // },
    {
      title: '活动时间',
      label: 'time',
      type: 'datePicker',
      width: '500px'
    }
  ],
  fromData: {
    // 表单数据
    keywords: '',
    status: '',
    type: '',
    time: []
  },
  formColumn: 4,
  loading: false,
  pagination: {
    current: 1,
    size: 10,
    total: 0
  },
  isPaginationShow: true,
  rTableData: [],
  columns: [
    {
      index: true,
      indexMethod (index) {
        return index + 1
      },
      width: 70,
      label: '编号'
    },
    { prop: 'title', label: '活动类型' },
    { prop: 'activityName', label: '子活动标题' },
    { prop: 'subtitle', label: '副标题' },
    { prop: 'beginTime', label: '开始时间' },
    { prop: 'endTime', label: '结束时间' },
    { prop: 'configStatusLabel', label: '活动状态' },
    { slot: 'invitationType' },
    { prop: 'limitedQuota', label: '每天可预约人数' },
    { prop: 'limitedQuotaTotal', label: '总可预约人数' },
    { prop: 'currentAppointmentCount', label: '当前预约人数' },
    { prop: 'sort', label: '排序' },
    { slot: 'action' }
  ]
})

onMounted(() => {
  getDataList()
  getDict()
})


// 活动类型、变更积分类型
const getDict = () => {
  constantAuery({
    className: 'ActivityConfigStatus'
  }).then(({ data: res }) => {
    if (res.code == 200) {
      const list = res.data.map(item => {
        return {
          label: item.value,
          value: item.code
        }
      })
      formLabels.value[1].options = list
    } else {
      ElMessage.error(res.msg)
    }
  })
  constantAuery({
    className: 'ActivityQueryType'
  }).then(({ data: res }) => {
    if (res.code == 200) {
      const list = res.data.map(item => {
        return {
          label: item.value,
          value: item.code
        }
      })
      formLabels.value[2].options = list
    } else {
      ElMessage.error(res.msg)
    }
  })
}

// 重置
const reset = () => {
  fromData.value = {
    // 表单数据
    keywords: '',
    status: '',
    type: '',
    time: []
  }
  pagination.value.current = 1
  pagination.value.size = 10
  getDataList()
}

// 查询
const search = () => {
  pagination.value.current = 1
  pagination.value.size = 10
  getDataList()
}

// 获取列表数据
const getDataList = (val, flag) => {
  loading.value = true
  if (flag == 'size') {
    pagination.value.size = val || 10
  } else {
    pagination.value.current = val || 1
  }
  const data = {
    current: pagination.value.current,
    size: pagination.value.size
  }
  if (fromData.value.keywords !== '') {
    data.keyword = fromData.value.keywords
  }
  if (fromData.value.status !== '') {
    data.configStatus = fromData.value.status
  }
  if (fromData.value.time.length > 0) {
    data.beginTime = fromData.value.time[0]
    data.endTime = fromData.value.time[1]
  }
  if (fromData.value.type !== '') {
    data.type = fromData.value.type
  }
  activityList(data).then(({ data: res }) => {
    loading.value = false
    if (res.code == 200) {
      rTableData.value = res.data.records
      pagination.value.total = Number(res.data.pageInfo.total)
    } else {
      ElMessage.error(res.msg)
      rTableData.value = []
      pagination.value.total = 0
    }
  })
}

// 新增活动弹窗
const addActivity = () => {
  router.push({
    name: `addThemeConfiguration`,
    query: {
      name: '新建'
    }
  })
}

// 编辑
const handleClickEdit = row => {
  router.push({
    name: `addThemeConfiguration`,
    query: {
      id: row.id,
      name: '修改'
    }
  })
}

// 查看详情
const handleClickLook = row => {
  router.push({
    name: `addThemeConfiguration`,
    query: {
      id: row.id,
      name: '详情'
    }
  })
}


// 删除
const handleClickDelete = row => {
  ElMessageBox.confirm('删除后该数据无法恢复，是否确认?', '提示：', {
    confirmButtonText: '确认',
    cancelButtonText: '取消',
    type: 'warning'
  })
    .then(() => {
      activityDelete({ id: row.id }).then(({ data: res }) => {
        if (res.code == 200) {
          ElMessage.success(res.msg)
          getDataList()
        } else {
          ElMessage.error(res.msg)
        }
      })
    })
    .catch(() => { })
}

const {
  loading,
  pagination,
  isPaginationShow,
  rTableData,
  columns,
  formLabels,
  fromData,
  formColumn,
  labelWidth
} = toRefs(initData)
</script>
<style lang="scss" scoped>
.user_dialog {
  .writeoff_box {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;

    span {
      width: 100px;
      text-align: right;
    }

    .dialog_input {
      width: 50%;
    }
  }

  .remark_box {
    align-items: flex-start;
  }

  .block {
    margin-top: 20px;
    display: flex;
    justify-content: right;
  }
}
</style>
<style lang="scss">
.writeoff_time {
  .el-date-editor {
    width: 50%;
  }
}
</style>
